<template>
  <b-card title="Industry Type">
    <b-modal ref="modal" v-model="promptDelete" title="Confirm Delete IndustryType">
      <p class="my-2">
        {{deleteMessage}}
      </p>
      <template #modal-footer>
        <b-button size="md" variant="danger" @click="doDelete">
          Yes
        </b-button>
        <b-button size="md" variant="success" @click="promptDelete = false">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button
            v-if="permission.add"
            variant="success"
            :to="{ name: 'industry-type-add' }"
          >
            Add Industry Type
          </b-button>
        </b-col>
        <b-col cols="4" />
        <b-col cols="4">
          
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="8" />
        <b-col cols="12" md="4">
          <b-form-input
            v-model="search"
            @input="(value) => doFiltering(value)"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" class="table-responsive">
          <b-table
            :items="industryTypes"
            :fields="fields"
            :filter="search"
            :busy.sync="tableLoading"
            show-empty
          >
            <template #cell(status)="row">
              {{ (row.item.status)?'Active':'Not Active' }}
            </template>
            <template #cell(createdAt)="row">
              {{ dates(row.item.createdAt) }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="permission.edit"
                >
                  <b-button
                    class="mr-1"
                    variant="outline-primary"
                    @click="toEditIndustryType(row.item._id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item style="text-align:center" v-if="permission.delete">
                  <b-button @click="deleteSelected(row.item)" variant="danger" class="mr-1">
                    <feather-icon icon="Trash2Icon"/>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="industryTypes.length"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
            first-number
            last-number
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { dateFormat, userAccess } from "@/utils/utils.js";
export default {
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Code",
        },
        {
          key: "label",
          label: "Name",
        },
        {
          key: "active",
          label: "Status",
        },
        { key: "createdAt", label: "Created At" },
        { key: "actions", label: "Action" },
      ],
      search: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: true,

      promptDelete:false,
      deleteMessage:"",
      toBeDeleted:"",
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      getIndustryTypes: "industryType/getIndustryTypes",
      deleteIndustryType:"industryType/deleteIndustryType"
    }),
    toEditIndustryType(id) {
      this.$router.push(`/industry-type/edit/${id}`);
    },
    dates(date){
      return dateFormat(date)
    },
    deleteSelected(item){
      this.toBeDeleted=item._id;
      this.deleteMessage = "Please confirm deleting Industry Type "+item.name;
      this.promptDelete=true;
    },

    doDelete() {
      this.tableLoading=true;
      this.deleteIndustryType(this.toBeDeleted).then(() => {
        this.getIndustryTypes({}).finally(()=>{this.tableLoading=false})
        this.promptDelete=false;
      }).catch((error) =>{
        // console.error(error)
        this.$bvToast.toast(`${error.response.data.errors[0].message}`, {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      industryTypes: "industryType/getIndustryTypes",
    }),
    permission() {
      return userAccess("Industry Type", "masterdata_menu");
    },
  },
  created() {
    document.title = "IndustryType | RSP";
    this.getIndustryTypes({}).finally(()=>{this.tableLoading=false})
  },
};
</script>
